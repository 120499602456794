<template lang="pug">
.app-menu
  v-list.flex-shrink-0(
    v-if="$vuetify.breakpoint.lgAndUp"
    color="transparent"
    rounded
  )
    v-list-item(
      v-for="({ to, icon, label }, index) in menu"
      :key="`menu-${index}`"
      :to="to"
      color="secondary"
    )
      v-list-item-icon.mr-2
        v-icon {{ icon }}
      v-list-item-title {{ label }}

  v-navigation-drawer(
    v-else
    v-model="showDrawer"
    app
  )
    v-list(
      color="transparent"
      rounded
    )
      v-list-item(
        v-for="({ to, icon, label }, index) in menu"
        :key="`menu-${index}`"
        :to="to"
        color="secondary"
      )
        v-list-item-icon.mr-2
          v-icon {{ icon }}
        v-list-item-title {{ label }}
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'

export default {
  name: 'AppMenu',
  computed: {
    ...mapState('App', ['drawer']),
    ...mapGetters('App', ['isCompanyOwner', 'isCompanyManager']),
    showDrawer: {
      get() {
        return this.drawer
      },
      set(v) {
        this.APP_SET_DRAWER(v)
      }
    },
    menu() {
      const list = [
        {
          to: '/search',
          icon: 'mdi-magnify',
          label: 'Søk'
        }
        /*
        {
          to: '/list',
          icon: 'mdi-phone-log',
          label: 'Ringelister'
        }
        */
      ]

      if (this.isCompanyOwner || this.isCompanyManager)
        list.push({
          to: '/member',
          icon: 'mdi-account-group',
          label: 'Brukere'
        })

      if (this.isCompanyOwner)
        list.push({
          to: '/billing',
          icon: 'mdi-credit-card',
          label: 'Betaling'
        })

      return list
    }
  },
  methods: mapMutations('App', ['APP_SET_DRAWER'])
}
</script>

<style lang="stylus" scoped>
.app-menu
  .v-list
    padding 0 38px 0 0
    width 280px

    .v-list-item

      &--active
        .v-icon
          color var(--v-secondary-base) !important

      .v-icon
        color #000

  .v-navigation-drawer
    .v-list
      padding 10px
      width unset
</style>
