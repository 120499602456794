<template lang="pug">
svg(
  :id="uuid"
  :width="width"
  :height="height"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 40.08 36.741"
)
  g(transform="translate(-285 -337)")
    path(
      d="M10.262,28.094a16.178,16.178,0,0,1-2.3-.154,11.227,11.227,0,0,1-1.952-.454,8.689,8.689,0,0,1-1.643-.742A7.9,7.9,0,0,1,3,25.727,9.566,9.566,0,0,1,.962,22.908,16.642,16.642,0,0,1,0,20.466H5.817A5.756,5.756,0,0,0,11,23.249a6.9,6.9,0,0,0,3.687-.936,3.525,3.525,0,0,0,1.705-2.427,2.052,2.052,0,0,0-.846-2.134A8.851,8.851,0,0,0,12.3,16.469l-2.856-.747a13.721,13.721,0,0,1-2.92-1.07,8.6,8.6,0,0,1-.937-.562l-1.978-5.2A10.688,10.688,0,0,0,9.19,1.148,13.169,13.169,0,0,1,10.566.64,14.987,14.987,0,0,1,14.973,0a12.384,12.384,0,0,1,4.375.72A7.272,7.272,0,0,1,22.34,2.734a6.76,6.76,0,0,1,1.416,5.58h-5.54a3.135,3.135,0,0,0-.783-2.4,4.417,4.417,0,0,0-3.3-1.084C11.43,4.83,9.559,6,9.247,7.887c-.324,1.632.872,2.71,3.764,3.39l2.349.587a10.708,10.708,0,0,1,5.427,2.711,5.724,5.724,0,0,1,.8,1.063,5.39,5.39,0,0,1,.524,1.235,6.054,6.054,0,0,1,.22,1.417A7.91,7.91,0,0,1,22.22,19.9a8.9,8.9,0,0,1-3.787,5.974,11.757,11.757,0,0,1-3.6,1.644,17.249,17.249,0,0,1-4.572.577Z"
      transform="translate(301.227 345.647)"
      :fill="dark ? '#FFFFFF' : '#062447'"
    )
    path(
      d="M5.327-190.846h5.779l1.615-9.69h4.2l3.578,9.69h6.38L22.838-201.47a9.7,9.7,0,0,0,6.126-7.781c.894-5.432-2.109-8.929-8.315-8.929H9.865ZM13.5-205.181l1.388-8.275h3.9c3.31,0,4.671,1.468,4.231,4.2-.44,2.709-2.269,4.071-5.592,4.071Z"
      transform="translate(279.673 555.18)"
      :fill="dark ? '#FFFFFF' : '#062447'"
    )
</template>

<script>
export default {
  name: 'Logo',
  props: {
    dark: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: null
    },
    height: {
      type: String,
      default: '100%'
    }
  },
  computed: {
    uuid() {
      return `logo-${this._uid}`
    }
  }
}
</script>

<style lang="stylus" scoped></style>
