const BASE_URL = 'https://api.tiles.mapbox.com/v4/geocode/'
const DATA_SET = 'mapbox.places'

class Geocoder {
  constructor() {}

  async geocode(query, opts) {
    if (!query) throw new Error('Query required')

    const url = new URL(`${BASE_URL}${DATA_SET}/${query}.json`)
    url.search = new URLSearchParams({
      access_token: process.env.VUE_APP_MAPBOX_PUBLIC_TOKEN,
      ...opts
    })
    const response = await fetch(url)
    const json = await response.json()
    return json
  }
}

export default new Geocoder()
