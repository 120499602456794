import Vue from 'vue'
import * as t from '@/store/types'
import { RestApi } from '@/service'

const state = {
  lists: []
}

const mutations = {
  [t.LIST_RESET](state) {
    state.lists = []
  },
  [t.LIST_ADD_LISTS](state, payload) {
    for (const list of payload) {
      const index = state.lists.findIndex(
        ({ list_id }) => list_id == list.list_id
      )
      if (index > -1) Vue.set(state.lists, index, list)
      else state.lists.push(list)
    }
    state.lists.sort((a, b) => b.created_at - a.created_at)
  },
  [t.LIST_REMOVE_LIST](state, payload) {
    const index = state.lists.findIndex(({ list_id }) => list_id == payload)
    if (index > -1) Vue.delete(state.lists, index)
  }
}

const actions = {
  async createList({ commit }, body) {
    const list = await RestApi.invoke('post', '/list', {
      body
    })
    commit(t.LIST_ADD_LISTS, [list])
    return list
  },
  async deleteList({ commit }, list_id = null) {
    commit(t.LIST_REMOVE_LIST, list_id)
    await RestApi.invoke('del', `/list/${list_id || ''}`)
  },
  async listList({ commit }, queryStringParameters = null) {
    const { data, lek } = await RestApi.invoke('get', '/list', {
      queryStringParameters
    })
    commit(t.LIST_ADD_LISTS, data)
    return { data, lek }
  },
  async purchaseList(_, list_id = null) {
    const { url } = await RestApi.invoke(
      'get',
      `/list/${list_id || ''}/purchase`
    )
    return url
  },
  async downloadList(_, list_id = null) {
    const { url } = await RestApi.invoke(
      'get',
      `/list/${list_id || ''}/download`
    )
    return url
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
