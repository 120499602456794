import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import no from 'vuetify/src/locale/no.ts'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#062447',
        secondary: '#5a3de6',
        accent: '#f7ab9e',
        error: '#FF5252',
        info: '#2196F3',
        success: '#3ccba5',
        warning: '#fedc95',
        salmon: '#FDE6E2',
        salad: '#d6f3eb',
        darksalad: '#9be9d5',
        purple: '#EBE8FC',
        whey: '#ffedcf',
        background: '#f8f8fb'
      }
    }
  },
  lang: {
    locales: { no },
    current: 'no'
  }
})
