<template lang="pug">
v-app(:style="{ background: $vuetify.theme.themes.light.background }")
  layout-snackbar
  router-view(v-if="sessionReady")
</template>

<script>
import { mapActions } from 'vuex'
import { LayoutSnackbar } from '@/components/layout'

export default {
  name: 'App',
  components: {
    LayoutSnackbar
  },
  data: () => ({
    sessionReady: false
  }),
  methods: mapActions('Cognito', ['fetchSession']),
  async mounted() {
    try {
      await this.fetchSession()
      this.sessionReady = true
    } catch (e) {
      // Silent
    }
  }
}
</script>
