// Module: App
export const APP_RESET = 'APP_RESET'
export const APP_SET_SNACKBAR = 'APP_SET_SNACKBAR'
export const APP_SET_DRAWER = 'APP_SET_DRAWER'
export const APP_SET_SUB_PRESENCE = 'APP_SET_SUB_PRESENCE'

// Module: Company
export const COMPANY_RESET = 'COMPANY_RESET'
export const COMPANY_SET_COMPANY = 'COMPANY_SET_COMPANY'
export const COMPANY_SET_ENABLED_MEMBERS = 'COMPANY_SET_ENABLED_MEMBERS'

// Module: List
export const LIST_RESET = 'LIST_RESET'
export const LIST_ADD_LISTS = 'LIST_ADD_LISTS'
export const LIST_REMOVE_LIST = 'LIST_REMOVE_LIST'

// Module: Log
export const LOG_RESET = 'LOG_RESET'

// Module: Member
export const MEMBER_RESET = 'MEMBER_RESET'
export const MEMBER_ADD_MEMBERS = 'MEMBER_ADD_MEMBERS'
export const MEMBER_REMOVE_MEMBER = 'MEMBER_REMOVE_MEMBER'
export const MEMBER_TOGGLE_MANAGER = 'MEMBER_TOGGLE_MANAGER'

// Module: Search
export const SEARCH_RESET = 'SEARCH_RESET'
export const SEARCH_SET_FILTERS = 'SEARCH_SET_FILTERS'

// Module: Stripe
export const STRIPE_RESET = 'STRIPE_RESET'
export const STRIPE_SET_CUSTOMER = 'STRIPE_SET_CUSTOMER'
export const STRIPE_ADD_PAYMENT_METHODS = 'STRIPE_ADD_PAYMENT_METHODS'
export const STRIPE_REMOVE_PAYMENT_METHODS = 'STRIPE_REMOVE_PAYMENT_METHODS'
export const STRIPE_ADD_PRODUCTS = 'STRIPE_ADD_PRODUCTS'
export const STRIPE_SET_SUBSCRIPTIONS = 'STRIPE_SET_SUBSCRIPTIONS'
export const STRIPE_ADD_SUBSCRIPTIONS = 'STRIPE_ADD_SUBSCRIPTIONS'
export const STRIPE_ADD_INVOICES = 'STRIPE_ADD_INVOICES'
