import { get } from 'lodash'
import * as t from '@/store/types'
import { RestApi } from '@/service'

const state = {
  company: null
}

const mutations = {
  [t.COMPANY_RESET](state) {
    state.company = null
  },
  [t.COMPANY_SET_COMPANY](state, payload) {
    state.company = payload
  },
  [t.COMPANY_SET_ENABLED_MEMBERS](state, payload) {
    if (state.company) state.company.enabled_members = payload
  }
}

const actions = {
  async readCompany({ commit }) {
    const company = await RestApi.invoke('get', '/company')
    commit(t.COMPANY_SET_COMPANY, company)
  },
  async updateCompany({ commit }, body) {
    const company = await RestApi.invoke('put', '/company', { body })
    commit(t.COMPANY_SET_COMPANY, company)
  },
  async transferCompany({ commit }, body) {
    const company = await RestApi.invoke('post', '/company/transfer', {
      body
    })
    commit(t.COMPANY_SET_COMPANY, company)
  },
  async enableMember({ commit }, member_id = null) {
    const enabled_members = await RestApi.invoke(
      'post',
      `/member/${member_id}/enable`
    )
    commit(t.COMPANY_SET_ENABLED_MEMBERS, enabled_members)
  },
  async disableMember({ commit }, member_id = null) {
    const enabled_members = await RestApi.invoke(
      'post',
      `/member/${member_id}/disable`
    )
    commit(t.COMPANY_SET_ENABLED_MEMBERS, enabled_members)
  }
}

const getters = {
  companyOwnerSub(state) {
    return get(state, 'company.owner_sub', null)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
