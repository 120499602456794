import Vue from 'vue'
import router from '@/router'
import store from '@/store'

// Styles
import '@/assets/styles/global.styl'
import '@mdi/font/css/materialdesignicons.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import '@fontsource/manrope'

// Libraries
import '@/lib/amplify'
import '@/lib/dayjs'
import '@/lib/mapbox'
import '@/lib/gtag'
import vuetify from '@/lib/vuetify'

// Services
import '@/service/RegisterServiceWorker'

// Misc.
import App from '@/App.vue'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
