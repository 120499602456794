import Vue from 'vue'
import Vuex from 'vuex'

import { RestApi, Websocket } from '@/service'

// Vuex modules
import App from '@/store/modules/App'
import Cognito from '@/store/modules/Cognito'
import Company from '@/store/modules/Company'
import List from '@/store/modules/List'
import Log from '@/store/modules/Log'
import Member from '@/store/modules/Member'
import Search from '@/store/modules/Search'
import Stripe from '@/store/modules/Stripe'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    App,
    Cognito,
    Company,
    List,
    Log,
    Member,
    Search,
    Stripe
  },
  strict: process.env.NODE_ENV !== 'production'
})

// Attach store instance so services can use it
RestApi.attachStore(store)
Websocket.attachStore(store)

export default store
