export default {
  data() {
    const d = {
      rules: {
        required: (msg) => (v) => !!v || msg,
        validPhoneNumber: (msg) => () => !!this.company_phone_number || msg,
        pwdLength: (v) =>
          (!!v && v.length > 5) || 'Passordet må være lengre enn 5',
        match: (v) => (!!v && v === this.password) || 'Passordene må matche',
        validURL: (v) => {
          const re =
            /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/
          return re.test(String(v).toLowerCase()) || 'Oppgi gylding nettside'
        },
        validEmail: (v) => {
          const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return re.test(String(v).toLowerCase()) || 'Oppgi gylding epost'
        }
      }
    }
    return d
  }
}
