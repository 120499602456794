import { get } from 'lodash'
import * as t from '@/store/types'
import { cloneDeep } from '@/lib/utils'
import { Websocket } from '@/service'

const state = {
  snackbar: {},
  drawer: false,
  sub_presence: []
}

const mutations = {
  [t.APP_RESET](state) {
    state.sub_presence = []
  },
  [t.APP_SET_SNACKBAR](state, payload) {
    state.snackbar = cloneDeep(payload)
  },
  [t.APP_SET_DRAWER](state, payload) {
    state.drawer = payload
  },
  [t.APP_SET_SUB_PRESENCE](state, payload) {
    state.sub_presence = payload
  }
}

const actions = {
  setSnackbar({ commit }, payload) {
    commit(t.APP_SET_SNACKBAR, payload)
  },
  async initApp({ dispatch }) {
    try {
      const jobs = [dispatch('Company/readCompany', null, { root: true })]
      const deferredJobs = [dispatch('Member/listMember', null, { root: true })]

      await Promise.allSettled(jobs)
      Promise.allSettled(deferredJobs)
      Websocket.init()
    } catch (e) {
      throw new Error(`Failed to init app (${e.message})`)
    }
  },
  takeOver({ commit }) {
    commit(t.APP_SET_SUB_PRESENCE, [])
    Websocket.send({ action: 'takeOver' })
  },
  reset({ commit }) {
    commit(t.APP_RESET)
    commit(`Company/${t.COMPANY_RESET}`)
    commit(`List/${t.LIST_RESET}`)
    commit(`Log/${t.LOG_RESET}`)
    commit(`Member/${t.MEMBER_RESET}`)
    commit(`Search/${t.SEARCH_RESET}`)
    commit(`Stripe/${t.STRIPE_RESET}`)
    Websocket.deinit()
  }
}

const getters = {
  isCompanyOwner(state, getters, rootState, rootGetters) {
    return (
      get(rootGetters, `['Cognito/userSub']`, false) ===
      get(rootGetters, `['Company/companyOwnerSub']`, null)
    )
  },
  isCompanyManager(state, getters, rootState, rootGetters) {
    const userGroups = get(rootGetters, `['Cognito/userGroups']`, false)
    return userGroups
      ? userGroups.includes(process.env.VUE_APP_USER_POOL_GROUP_MANAGER)
      : false
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
