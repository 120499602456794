import Auth from '@aws-amplify/auth'
import { RestApi } from '@/service'

const state = {
  session: {},
  user: {}
}

const mutations = {
  setSession(state, payload) {
    state.session = payload
  },
  setUser(state, payload) {
    state.user = payload
  }
}

const actions = {
  async fetchSession({ commit }) {
    try {
      const session = await Auth.currentSession()
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true })
      commit('setSession', session)
      commit('setUser', user)
      return session
    } catch (e) {
      // Auth may annoyingly throw a string
      if (e instanceof Error) throw e
    }
  },

  async signInUser({ commit }, { username, password }) {
    const user = await Auth.signIn(username, password)

    // Password requires changing
    if (user.challengeName == 'NEW_PASSWORD_REQUIRED') return user

    commit('setUser', user)
    commit('setSession', user.signInUserSession)
    if (localStorage) localStorage.setItem('USER', JSON.stringify(user))
    return user
  },

  async completeNewPassword({ commit }, { user, password }) {
    user = await Auth.completeNewPassword(user, password)
    commit('setUser', user)
    commit('setSession', user.signInUserSession)
    if (localStorage) localStorage.setItem('USER', JSON.stringify(user))
    return user
  },

  async registerUser(_, { username, password, nickname, company_info }) {
    await RestApi.invoke(
      'post',
      '/auth/user',
      {
        body: { username, password, nickname, company_info }
      },
      false
    )
  },

  async confirmUser(_, { username, code }) {
    return await Auth.confirmSignUp(username, code)
  },

  async resendConfirmation(_, { username }) {
    return await Auth.resendSignUp(username)
  },

  async updateUser({ dispatch }, attributes) {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true })
    await Auth.updateUserAttributes(user, attributes)
    await dispatch('fetchSession')
  },

  async changePassword({ state }, { oldPassword, newPassword }) {
    return await Auth.changePassword(state.user, oldPassword, newPassword)
  },

  async forgotPassword(_, { username }) {
    return await Auth.forgotPassword(username)
  },

  async changeForgottenPassword(_, { username, code, newPassword }) {
    return await Auth.forgotPasswordSubmit(username, code, newPassword)
  },

  async signOut({ commit, getters }) {
    const { isLoggedIn } = getters
    if (!isLoggedIn) throw new Error('User not logged in.')

    const result = await Auth.signOut()
    commit('setUser', {})
    commit('setSession', {})

    if (localStorage) localStorage.removeItem('USER')

    return result
  }
}

const getters = {
  // TODO: ensure best method to verify this
  isLoggedIn: (store = {}) => {
    const session = store.session
    if (!session) return false
    const accessToken = session.accessToken
    if (!accessToken) return false
    const hasToken = accessToken.jwtToken
    const isActive = new Date(accessToken.payload.exp * 1000) > new Date()
    const isMe = accessToken.payload.username === store.user.username
    return hasToken && isActive && isMe
  },
  session: (store = {}) =>
    'session' in store && Object.keys(store.session).length !== 0
      ? store.session
      : false,
  userSub: (store = {}) => {
    if (store.user && store.user.attributes) {
      return store.user.attributes.sub
    } else if (store.user && store.user.userSub) {
      return store.user.userSub
    } else {
      return false
    }
  },
  username: (store = {}) =>
    store.user && store.user.user ? store.user.user.username : false,
  userAttributes: (store = {}) =>
    store.user && store.user.attributes ? store.user.attributes : false,
  userGroups: (store = {}) =>
    store.session &&
    store.session.accessToken &&
    store.session.accessToken.payload &&
    store.session.accessToken.payload['cognito:groups']
      ? store.session.accessToken.payload['cognito:groups']
      : false
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
