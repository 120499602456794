var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"height":"80","color":"#FFF","elevation":"0","app":"","tile":""}},[(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-app-bar-nav-icon',{staticClass:"ml-0 mr-4",attrs:{"color":"black"},on:{"click":function($event){return _vm.APP_SET_DRAWER(true)}}}):_vm._e(),_c('v-layout',{attrs:{"justify-center":"","fill-height":""}},[_c('v-flex',{staticClass:"d-flex align-center",attrs:{"sm12":"","lg8":"","xl6":""}},[_c('ui-logo',{attrs:{"width":"35"}}),(_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"text-h5 font-weight-bold primary--text ml-4"},[_vm._v("Reservertsøk.no")]):_vm._e(),_c('v-spacer'),_c('v-menu',_vm._b({attrs:{"nudge-top":"8","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list',{attrs:{"color":"transparent","rounded":"","dense":""}},[_c('v-list-item',_vm._g(_vm._b({staticClass:"px-0"},'v-list-item',attrs,false),on),[_c('v-list-item-avatar',{staticClass:"mx-2"},[_c('v-icon',{attrs:{"color":"darksalad","large":""}},[_vm._v("mdi-account-circle")])],1),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-list-item-content',{staticClass:"d-block mr-0"},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.nickname))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.company.name))])],1):_vm._e(),_c('v-list-item-action',{staticClass:"mr-4",class:_vm.$vuetify.breakpoint.smAndUp ? 'ml-2' : 'ml-0'},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)],1)],1)]}}])},'v-menu',_vm.menuProps(),false),[_c('v-list',[_vm._l((_vm.menu),function(ref,index){
var to = ref.to;
var icon = ref.icon;
var label = ref.label;
return _c('v-list-item',{key:("menu-" + index),attrs:{"to":to}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(_vm._s(icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(label))])],1)}),_c('v-list-item',{attrs:{"to":"/signout"}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-exit-to-app")])],1),_c('v-list-item-title',[_vm._v("Logg ut")])],1)],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }