import Vue from 'vue'
import * as t from '@/store/types'
import { RestApi } from '@/service'

const state = {
  customer: null,
  payment_methods: [],
  products: [],
  subscriptions: [],
  invoices: []
}

const mutations = {
  [t.STRIPE_RESET]() {
    state.customer = null
    state.payment_methods = []
    state.products = []
    state.subscriptions = []
    state.invoices = []
  },
  [t.STRIPE_SET_CUSTOMER](state, payload) {
    state.customer = payload
  },
  [t.STRIPE_ADD_PAYMENT_METHODS](state, payload) {
    for (const payment_method of payload) {
      const index = state.payment_methods.findIndex(
        ({ id }) => id == payment_method.id
      )
      if (index > -1) Vue.set(state.payment_methods, index, payment_method)
      else state.payment_methods.push(payment_method)
    }
  },
  [t.STRIPE_REMOVE_PAYMENT_METHODS](state, payload) {
    const index = state.payment_methods.findIndex(({ id }) => id == payload)
    if (index > -1) Vue.delete(state.payment_methods, index)
  },
  [t.STRIPE_ADD_PRODUCTS](state, payload) {
    for (const product of payload) {
      const index = state.products.findIndex(({ id }) => id == product.id)
      if (index > -1) Vue.set(state.products, index, product)
      else state.products.push(product)
    }
  },
  [t.STRIPE_SET_SUBSCRIPTIONS](state, payload) {
    state.subscriptions = payload
  },
  [t.STRIPE_ADD_SUBSCRIPTIONS](state, payload) {
    for (const subscription of payload) {
      const index = state.subscriptions.findIndex(
        ({ id }) => id == subscription.id
      )
      if (index > -1) Vue.set(state.subscriptions, index, subscription)
      else state.subscriptions.push(subscription)
    }
  },
  [t.STRIPE_ADD_INVOICES](state, payload) {
    for (const invoice of payload) {
      const index = state.invoices.findIndex(({ id }) => id == invoice.id)
      if (index > -1) Vue.set(state.invoices, index, invoice)
      else state.invoices.push(invoice)
    }
    state.invoices.sort((a, b) => b.created - a.created)
  }
}

const actions = {
  async readCustomer({ commit }) {
    const customer = await RestApi.invoke('get', '/stripe/customer')
    commit(t.STRIPE_SET_CUSTOMER, customer)
  },
  async updateCustomer({ commit }, body) {
    const customer = await RestApi.invoke('put', '/stripe/customer', { body })
    commit(t.STRIPE_SET_CUSTOMER, customer)
  },
  async attachPaymentMethod({ commit }, body) {
    const { payment_method, customer } = await RestApi.invoke(
      'post',
      '/stripe/payment_method',
      { body }
    )
    commit(t.STRIPE_ADD_PAYMENT_METHODS, [payment_method])
    commit(t.STRIPE_SET_CUSTOMER, customer)
  },
  async detachPaymentMethod({ commit }, id = null) {
    commit(t.STRIPE_REMOVE_PAYMENT_METHODS, id)
    const { customer } = await RestApi.invoke(
      'del',
      `/stripe/payment_method/${id}`
    )
    commit(t.STRIPE_SET_CUSTOMER, customer)
  },
  async listPaymentMethod({ commit }) {
    const { data } = await RestApi.invoke('get', '/stripe/payment_method')
    commit(t.STRIPE_ADD_PAYMENT_METHODS, data)
  },
  async listProduct({ commit }) {
    const { data } = await RestApi.invoke('get', '/stripe/product')
    commit(t.STRIPE_ADD_PRODUCTS, data)
  },
  async updateSubscription({ commit }, body) {
    const subscription = await RestApi.invoke('put', '/stripe/subscription', {
      body
    })
    if (subscription === '') commit(t.STRIPE_ADD_SUBSCRIPTIONS, [subscription])
    return subscription
  },
  async listSubscription({ commit }) {
    const { data } = await RestApi.invoke('get', '/stripe/subscription')
    commit(t.STRIPE_SET_SUBSCRIPTIONS, data)
  },
  async listInvoice({ commit }) {
    const { data } = await RestApi.invoke('get', '/stripe/invoice')
    commit(t.STRIPE_ADD_INVOICES, data)
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
