import API from '@aws-amplify/api'
import { get } from 'lodash'
import config from '@/config'

class RestApi {
  constructor() {
    this.store = null
  }

  attachStore(store) {
    this.store = store
  }

  isLoggedIn() {
    return this.store ? this.store.getters['Cognito/isLoggedIn'] : false
  }

  async invoke(method, path, opts = {}, authCheck = true) {
    if (authCheck && !this.isLoggedIn()) throw new Error('Not authenticated')

    method = ['get', 'post', 'put', 'del', 'head'].includes(method)
      ? method
      : 'get'

    try {
      const res = await API[method](config.env, path, opts)
      if (res.error) throw new Error(res.error)
      return res
    } catch (e) {
      const errorMessage = get(e, 'response.data.error', null)
      throw new Error(errorMessage || e)
    }
  }
}

export default new RestApi()
