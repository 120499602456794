import Vue from 'vue'
import VueRouter from 'vue-router'

// /auth
const Auth = () => import(/* webpackChunkName: "auth" */ '@/views/auth/Auth')
const AuthSignUp = () =>
  import(/* webpackChunkName: "auth" */ '@/views/auth/AuthSignUp')
const AuthVerify = () =>
  import(/* webpackChunkName: "auth" */ '@/views/auth/AuthVerify')
const AuthForgot = () =>
  import(/* webpackChunkName: "auth" */ '@/views/auth/AuthForgot')
const AuthSignIn = () =>
  import(/* webpackChunkName: "auth" */ '@/views/auth/AuthSignIn')

// /
const Index = () => import(/* webpackChunkName: "index" */ '@/views/Index')

// /signout
const SignOut = () =>
  import(/* webpackChunkName: "signout" */ '@/views/SignOut')

// /profile
const Profile = () =>
  import(/* webpackChunkName: "profile" */ '@/views/Profile')

// /company
const Company = () =>
  import(/* webpackChunkName: "company" */ '@/views/Company')

// /search
const Search = () =>
  import(/* webpackChunkName: "search" */ '@/views/search/Search')
const SearchReservert = () =>
  import(/* webpackChunkName: "search" */ '@/views/search/SearchReservert')
const SearchFiltrert = () =>
  import(/* webpackChunkName: "search" */ '@/views/search/SearchFiltrert')

// /list
const List = () => import(/* webpackChunkName: "list" */ '@/views/list/List')
const ListCreate = () =>
  import(/* webpackChunkName: "list" */ '@/views/list/ListCreate')
const ListDownload = () =>
  import(/* webpackChunkName: "list" */ '@/views/list/ListDownload')
const ListRotation = () =>
  import(/* webpackChunkName: "list" */ '@/views/list/ListRotation')

// /member
const Member = () => import(/* webpackChunkName: "member" */ '@/views/Member')

// /billing
const Billing = () =>
  import(/* webpackChunkName: "billing" */ '@/views/billing/Billing')
const BillingCustomer = () =>
  import(/* webpackChunkName: "billing" */ '@/views/billing/BillingCustomer')
const BillingPaymentMethod = () =>
  import(
    /* webpackChunkName: "billing" */ '@/views/billing/BillingPaymentMethod'
  )
const BillingSubscription = () =>
  import(
    /* webpackChunkName: "billing" */ '@/views/billing/BillingSubscription'
  )
const BillingInvoice = () =>
  import(/* webpackChunkName: "billing" */ '@/views/billing/BillingInvoice')

Vue.use(VueRouter)

const routes = [
  {
    path: '/auth',
    component: Auth,
    children: [
      {
        path: 'signup',
        name: 'authsignup',
        meta: { title: 'Opprett bruker' },
        component: AuthSignUp
      },
      {
        path: 'verify/:code?/:data?',
        name: 'authverify',
        meta: { title: 'Verifiser' },
        component: AuthVerify
      },
      {
        path: 'forgot/:code?/:data?',
        name: 'authforgot',
        meta: { title: 'Glemt passord' },
        component: AuthForgot
      },
      {
        path: ':data?',
        name: 'authsignin',
        meta: { title: 'Logg inn' },
        component: AuthSignIn
      }
    ]
  },
  {
    path: '/',
    component: Index,
    children: [
      {
        path: '',
        redirect: 'search'
      },
      {
        path: 'signout',
        name: 'signout',
        component: SignOut
      },
      {
        path: 'profile',
        name: 'profile',
        meta: { title: 'Min profil' },
        component: Profile
      },
      {
        path: 'company',
        name: 'company',
        meta: { title: 'Min bedrift' },
        component: Company
      },
      {
        path: 'search',
        component: Search,
        children: [
          {
            path: '',
            name: 'searchreservert',
            meta: { title: 'Reservert søk' },
            component: SearchReservert
          },
          {
            path: 'filtrert',
            name: 'searchfiltrert',
            meta: { title: 'Filtrert søk' },
            component: SearchFiltrert
          }
        ]
      },
      {
        path: 'list',
        component: List,
        children: [
          {
            path: '',
            name: 'listcreate',
            meta: { title: 'Opprett liste' },
            component: ListCreate
          },
          {
            path: 'download',
            name: 'listdownload',
            meta: { title: 'Last ned lister' },
            component: ListDownload
          },
          {
            path: 'rotation',
            name: 'listrotation',
            meta: { title: 'Rotering av lister' },
            component: ListRotation
          }
        ]
      },
      {
        path: 'member',
        name: 'member',
        meta: { title: 'Brukere' },
        component: Member
      },
      {
        path: 'billing',
        component: Billing,
        children: [
          {
            path: '',
            name: 'billingcustomer',
            meta: { title: 'Betalingsinformasjon' },
            component: BillingCustomer
          },
          {
            path: 'payment_method',
            name: 'billingpayment_method',
            meta: { title: 'Betalingsmåter' },
            component: BillingPaymentMethod
          },
          {
            path: 'subscription',
            name: 'billingsubscription',
            meta: { title: 'Abonnement' },
            component: BillingSubscription
          },
          {
            path: 'invoice',
            name: 'billinginvoice',
            meta: { title: 'Fakturaer' },
            component: BillingInvoice
          }
        ]
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to) => {
  Vue.nextTick(() => {
    const title = to.meta.title || null
    document.title = `${title ? `${title} - ` : ''}Reservertsøk.no`
  })
})

export default router
