// Route un-authed to /auth
export default {
  data: () => ({
    guard: false
  }),
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.$store.getters['Cognito/isLoggedIn']) vm.$router.push('/auth')
      else vm.guard = true
    })
  }
}
