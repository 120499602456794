var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-menu"},[(_vm.$vuetify.breakpoint.lgAndUp)?_c('v-list',{staticClass:"flex-shrink-0",attrs:{"color":"transparent","rounded":""}},_vm._l((_vm.menu),function(ref,index){
var to = ref.to;
var icon = ref.icon;
var label = ref.label;
return _c('v-list-item',{key:("menu-" + index),attrs:{"to":to,"color":"secondary"}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v(_vm._s(icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(label))])],1)}),1):_c('v-navigation-drawer',{attrs:{"app":""},model:{value:(_vm.showDrawer),callback:function ($$v) {_vm.showDrawer=$$v},expression:"showDrawer"}},[_c('v-list',{attrs:{"color":"transparent","rounded":""}},_vm._l((_vm.menu),function(ref,index){
var to = ref.to;
var icon = ref.icon;
var label = ref.label;
return _c('v-list-item',{key:("menu-" + index),attrs:{"to":to,"color":"secondary"}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v(_vm._s(icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(label))])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }