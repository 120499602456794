// Route authed to /
export default {
  data: () => ({
    guard: false
  }),
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.getters['Cognito/isLoggedIn']) vm.$router.push('/')
      else vm.guard = true
    })
  }
}
