import Vue from 'vue'
import { get } from 'lodash'
import * as t from '@/store/types'
import { RestApi } from '@/service'
import { cloneDeep } from '@/lib/utils'

const state = {
  members: []
}

const mutations = {
  [t.MEMBER_RESET](state) {
    state.members = []
  },
  [t.MEMBER_ADD_MEMBERS](state, payload) {
    for (const member of payload) {
      const index = state.members.findIndex(({ sub }) => sub == member.sub)
      if (index > -1) Vue.set(state.members, index, member)
      else state.members.push(member)
    }

    const sorted = []
    const members = cloneDeep(state.members)
    const admin = members.find(({ company_owner }) => company_owner === true)
    if (admin) sorted.push(admin)
    const managers = members.filter(
      ({ groups }) =>
        groups && groups.includes(process.env.VUE_APP_USER_POOL_GROUP_MANAGER)
    )
    sorted.push(...managers)
    const others = members.filter(
      ({ company_owner, groups }) =>
        company_owner === false &&
        groups &&
        !groups.includes(process.env.VUE_APP_USER_POOL_GROUP_MANAGER)
    )
    sorted.push(...others)
    state.members = sorted
  },
  [t.MEMBER_REMOVE_MEMBER](state, payload) {
    const index = state.members.findIndex(({ sub }) => sub == payload)
    if (index > -1) Vue.delete(state.members, index)
  },
  [t.MEMBER_TOGGLE_MANAGER](state, payload) {
    const index = state.members.findIndex(({ sub }) => sub == payload)
    if (index < 0) return

    const member = cloneDeep(state.members[index])
    const groups = get(member, 'groups', [])
    const is_manager = groups.includes(
      process.env.VUE_APP_USER_POOL_GROUP_MANAGER
    )

    member.groups = is_manager
      ? groups.filter(
          (group_name) =>
            group_name !== process.env.VUE_APP_USER_POOL_GROUP_MANAGER
        )
      : [...groups, process.env.VUE_APP_USER_POOL_GROUP_MANAGER]

    Vue.set(state.members, index, member)
  }
}

const actions = {
  async createMember({ commit }, body) {
    const member = await RestApi.invoke('post', '/member', {
      body
    })
    commit(t.MEMBER_ADD_MEMBERS, [member])
  },
  async deleteMember({ commit }, sub = null) {
    commit(t.MEMBER_REMOVE_MEMBER, sub)
    await RestApi.invoke('del', `/member/${sub || ''}`)
  },
  async listMember({ commit }) {
    const { data } = await RestApi.invoke('get', '/member')
    commit(t.MEMBER_ADD_MEMBERS, data)
  },
  async toggleManagerMember({ commit }, sub = null) {
    commit(t.MEMBER_TOGGLE_MANAGER, sub)
    await RestApi.invoke('put', `/member/${sub || ''}/manager`)
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
