<template lang="pug">
v-snackbar(
  v-model="model"
  :color="snackbar.type"
  :timeout="4000"
  rounded="pill"
  right
)
  v-icon.mr-2(
    :dark="snackbar.type !== 'warning'"
    v-text="icon"
  )
  span(
    :class="snackbar.type === 'warning' ? 'black--text' : 'white--text'"
    v-text="snackbar.msg"
  )
</template>

<script>
import { mapState } from 'vuex'

const ICON_MAP = {
  error: 'mdi-alert',
  info: 'mdi-information',
  success: 'mdi-check',
  warning: 'mdi-alert',
  undefined: 'mdi-progress-question'
}

export default {
  name: 'Snackbar',
  data: () => ({
    model: false
  }),
  computed: {
    ...mapState('App', ['snackbar']),
    icon() {
      return ICON_MAP[this.snackbar.type] || ICON_MAP.undefined
    }
  },
  watch: {
    snackbar() {
      this.model = true
    }
  }
}
</script>
