import { get } from 'lodash'
import * as t from '@/store/types'

class Websocket {
  constructor() {
    this.socket = null
    this.store = null
    this.interval = null
    this.ttl = 3600000 - 60000 // TTL 1h - margin
  }

  init() {
    this.connect()
    this.interval = setInterval(() => {
      this.close()
      this.connect()
      console.log('--- WS reconnect')
    }, this.ttl)
  }

  deinit() {
    this.close()
    clearInterval(this.interval)
  }

  attachStore(store) {
    this.store = store
  }

  async token() {
    try {
      await this.store.dispatch('Cognito/fetchSession')
      return get(
        this.store.getters['Cognito/session'],
        'accessToken.jwtToken',
        null
      )
    } catch (e) {
      return null
    }
  }

  async connect() {
    try {
      const token = await this.token()
      if (!token) throw new Error('No valid token')

      this.socket = new WebSocket(
        `${process.env.VUE_APP_API_GATEWAY_WS_ENDPOINT || ''}?token=${token}`
      )
      this.socket.onopen = (e) => this.onopen(e)
      this.socket.onmessage = (e) => this.onmessage(e)
    } catch (e) {
      console.log('--- WS connect error:', e)
    }
  }

  send(message) {
    if (!this.socket || this.socket.readyState !== WebSocket.OPEN) return
    this.socket.send(JSON.stringify(message))
  }

  close() {
    if (!this.socket) return

    try {
      this.socket.close()
      this.socket = null
    } catch (e) {
      console.log('--- WS close error:', e)
    }
  }

  onopen() {
    // ...
  }

  onmessage(e) {
    try {
      const { type, data } = JSON.parse(e.data)
      console.log('--- WS onmessage:', type, data)

      switch (type) {
        case 'SUB_PRESENCE':
          this.store.commit(`App/${t.APP_SET_SUB_PRESENCE}`, data)
          break
        case 'TAKE_OVER':
          this.store.dispatch('Cognito/signOut')
          this.store.dispatch('App/reset')
          this.store.dispatch('App/setSnackbar', {
            type: 'error',
            msg: 'Økten ble tatt over'
          })
          setTimeout(() => (window.location.href = '/'), 500)

          break
      }
    } catch (e) {
      console.log(e)
      return
    }
  }
}

export default new Websocket()
