<template lang="pug">
.sub-presence.pa-8(v-if="sub_presence.length > 1")
  img(
    :src="require('../../assets/img/overcrowded.jpg')"
    style="max-width:35%"
  )
  .text-h4.mt-8.mb-4.primary--text Her var det fullt, gitt!
  .text-h6.mb-8.primary--text
    | Du kan dessverre ikke være innlogget flere plasser samtidig på samme konto.
  div
    v-btn.mr-6(
      @click="takeOver"
      color="secondary"
      depressed rounded large
    )
      v-icon(left small) mdi-hand-back-left
      | Ta over økt
    v-btn(
      to="/signout"
      color="secondary"
      depressed rounded large
    )
      v-icon(left small) mdi-exit-to-app
      | Logg ut
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'SubPresence',
  data: () => ({}),
  computed: mapState('App', ['sub_presence']),
  methods: mapActions('App', ['takeOver'])
}
</script>

<style lang="stylus" scoped>
.sub-presence
  background rgba(0, 138, 255, .15)
  -webkit-backdrop-filter blur(5px)
  backdrop-filter blur(5px)
  display flex
  align-items center
  justify-content center
  flex-direction column
  position fixed
  top 0
  right 0
  left 0
  bottom 0
  z-index 100

  img
    border-radius 50px
    box-shadow 0 5px 15px rgba(29, 83, 139, .5)
</style>
