import * as t from '@/store/types'
import { RestApi } from '@/service'
import { cloneDeep } from '@/lib/utils'

const state = {
  filters: {}
}

const mutations = {
  [t.SEARCH_RESET](state) {
    state.filters = {}
  },
  [t.SEARCH_SET_FILTERS](state, payload) {
    state.filters = cloneDeep(payload)
  }
}

const actions = {
  async reservert(_, number = null) {
    const result = await RestApi.invoke(
      'get',
      `/search/reservert/${number || ''}`
    )
    return result
  },
  async filtrert(_, body) {
    const result = await RestApi.invoke('post', '/search/filtrert', {
      body
    })
    return result
  },
  async distinct(_, body) {
    const result = await RestApi.invoke('post', '/search/distinct', {
      body
    })
    return result
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
