import * as t from '@/store/types'
import { RestApi } from '@/service'

const state = {}

const mutations = {
  [t.LOG_RESET](state) {
    state.lists = []
  }
}

const actions = {
  async createLog(_, body) {
    await RestApi.invoke('post', '/log', { body })
  },
  async listLog(_, number = null) {
    return await RestApi.invoke('get', `/log/${number || ''}`)
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
