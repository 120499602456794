export default {
  methods: {
    menuProps(props = null) {
      return {
        ...props,
        contentClass: 'drop-front-dropdown',
        transition: 'drop-front',
        origin: 'center top',
        offsetY: true
      }
    }
  }
}
