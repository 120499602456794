<template lang="pug">
v-app-bar(
  height="80"
  color="#FFF"
  elevation="0"
  app tile
)
  v-app-bar-nav-icon.ml-0.mr-4(
    v-if="$vuetify.breakpoint.mdAndDown"
    @click="APP_SET_DRAWER(true)"
    color="black"
  )
  v-layout(justify-center fill-height)
    v-flex.d-flex.align-center(sm12 lg8 xl6)

      ui-logo(width="35")
      .text-h5.font-weight-bold.primary--text.ml-4(
        v-if="$vuetify.breakpoint.smAndUp"
      ) Reservertsøk.no

      v-spacer

      v-menu(
        v-bind="menuProps()"
        nudge-top="8"
        offset-y
      )
        template(#activator="{ on, attrs }")
          v-list(
            color="transparent"
            rounded dense
          )
            v-list-item.px-0(
              v-bind="attrs"
              v-on="on"
            )
              v-list-item-avatar.mx-2
                v-icon(
                  color="darksalad"
                  large
                ) mdi-account-circle
              v-list-item-content.d-block.mr-0(
                v-if="$vuetify.breakpoint.smAndUp"
              )
                v-list-item-title {{ nickname }}
                v-list-item-subtitle {{ company.name }}
              v-list-item-action.mr-4(
                :class="$vuetify.breakpoint.smAndUp ? 'ml-2' : 'ml-0'"
              )
                v-icon mdi-chevron-down
        //- Dropdown
        v-list
          v-list-item(
            v-for="({ to, icon, label }, index) in menu"
            :key="`menu-${index}`"
            :to="to"
          )
            v-list-item-icon.mr-4
              v-icon(color="black") {{ icon }}
            v-list-item-title {{ label }}
          v-list-item(
            to="/signout"
          )
            v-list-item-icon.mr-4
              v-icon(color="black") mdi-exit-to-app
            v-list-item-title Logg ut
</template>

<script>
import { get } from 'lodash'
import { mapState, mapMutations, mapGetters } from 'vuex'
import { menuProps } from '@/mixins'
import { UiLogo } from '@/components/ui'

export default {
  name: 'AppBar',
  mixins: [menuProps],
  components: {
    UiLogo
  },
  computed: {
    ...mapState('Company', ['company']),
    ...mapGetters('App', ['isCompanyOwner']),
    ...mapGetters('Cognito', ['userAttributes']),
    nickname() {
      const nickname = get(this.userAttributes, 'nickname', null)
      const email = get(this.userAttributes, 'email', 'Anonymous')
      return nickname || email
    },
    menu() {
      const list = [
        {
          to: '/profile',
          icon: 'mdi-account-circle',
          label: 'Min profil'
        }
      ]

      if (this.isCompanyOwner) {
        list.push({
          to: '/company',
          icon: 'mdi-briefcase',
          label: 'Min bedrift'
        })
      }

      return list
    }
  },
  methods: mapMutations('App', ['APP_SET_DRAWER'])
}
</script>

<style lang="stylus" scoped>
.v-app-bar
  box-shadow rgba(0, 0, 0, .08) 0px 1px 6px, rgba(0, 0, 0, 0.05) 0px 1px 2px !important
</style>
